<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Proposals</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Proposals"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.proposalForm.openForm()"
                  v-on="on"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Proposal</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  v-on="on"
                  :to="{ name: 'module-drum-proposals-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="proposals"
        no-data-text="There are no proposals"
      >
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'module-drum-proposals-individual',
              params: { tourId: item.id },
            }"
            >{{ item.name }}</router-link
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-drum-proposals-individual',
                  params: { tourId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="archiveProposal(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive Proposal</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog
      v-model="archiveDialog.open"
      max-width="400"
      @click:outside="resetArchiveDialog()"
    >
      <v-card>
        <v-card-title class="headline">Archive Proposal</v-card-title>
        <v-card-text>
          Are you sure you want to archive
          {{ archiveDialog.proposal.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetArchiveDialog()"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archiveDialog.loading"
            @click="confirmArchiveProposal()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ProposalForm ref="proposalForm" :isEditing="false" />
  </div>
</template>

<script>
import ProposalForm from "../components/ProposalForm";

export default {
  components: {
    ProposalForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Proposals",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Title", value: "name" },
        { text: "Date", value: "formatted_dates.date" },
        { text: "Lead Customer", value: "lead_customer.full_name" },
        {
          text: "Last Updated",
          value: "formatted_dates.updated_at",
          sortable: false,
        },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
      archiveDialog: {
        open: false,
        loading: false,
        proposal: {},
        error: false,
      },
    };
  },

  computed: {
    proposals() {
      let proposals = this.$store.getters["drum/tours/proposals"];
      if (this.searchTerm !== "") {
        proposals = proposals.filter(p => {
          const name = p.name.toLowerCase();
          const leadCustomer = p.lead_customer
            ? p.lead_customer.full_name.toLowerCase()
            : "";
          const searchTerm = this.searchTerm.toLowerCase();
          return name.includes(searchTerm) || leadCustomer.includes(searchTerm);
        });
      }

      return proposals;
    },
  },

  methods: {
    archiveProposal: function(proposal) {
      this.archiveDialog.proposal = proposal;
      this.archiveDialog.open = true;
    },
    resetArchiveDialog: function() {
      this.archiveDialog.open = false;
      this.archiveDialog.proposal = {};
      this.archiveDialog.loading = false;
      this.archiveDialog.error = false;
    },
    confirmArchiveProposal: function() {
      const appId = this.$route.params.id;
      const id = this.archiveDialog.proposal.id;
      this.archiveDialog.loading = true;
      this.archiveDialog.error = false;

      this.$store
        .dispatch("drum/tours/deleteProposal", {
          appId,
          id,
        })
        .then(() => {
          this.resetArchiveDialog();
        })
        .catch(() => {
          this.archiveDialog.loading = false;
          this.archiveDialog.error = true;
        });
    },
  },
};
</script>
